<template>
   <div class="container-fluid page-body-wrapper full-page-wrapper" style="margin-top: -97px;">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <h6 class="fw-light">Faça login para continuar.</h6>
              <form class="pt-3">
                <div class="form-group">
                  <input type="email" @keypress.enter="logar" v-model="usuario" class="form-control form-control-lg" id="exampleInputUsuario" placeholder="Usuário">
                </div>
                <div class="form-group">
                  <input type="password" @keypress.enter="logar" v-model="senha" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Senha">
                </div>
                <div class="mt-3">
                  <a class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" @click="logar">Logar</a>
                </div>

                <div v-if="erro.length > 0" class="alert alert-danger mt-4" role="alert">
                    {{this.erro}}
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>

     <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="true"></loading>
    </div>
</template>

<script>
import axiosApi from '@/axios'
import router from '@/router'
import { mapMutations } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    name: 'Login',
    components:{
        Loading
    },

    data(){
        return{
            usuario: '',
            senha: '',
            erro: '',
            isLoading: false
        }
    },

    methods:{
        ...mapMutations(['loginUsuario']),
        
        logar(){
            var data = {'usuario': this.usuario, 'senha': this.senha}
            this.erro = ''
            this.isLoading = true
            axiosApi.post('/login', data)
                .then( (response) =>{
                    this.loginUsuario(response.data.token)
                    router.push('/')
            })
                .catch( (error) => {
                    this.erro = error.response.data.erro
            }) .then(()=>{
                this.isLoading = false
            })
        }
    }

}
</script>

<style>

</style>